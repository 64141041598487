'use strict';

jQuery.noConflict();


jQuery( document ).ready( function( $ ) {

	var siteInit = {

		DOMready: function() {

			$.scrollIt({
				topOffset: -245
			});

			$.cookieBanner({
		        cookiePageUrl: 'privacy.html'
		    });

			if ( $('.slider').length > 0 ) {
				siteInit.sliderFunction();
			}

			if ( $('.content-page__section').length > 0 ) {
				siteInit.inViewElement();
			}

			if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
				siteInit.modal();
			}

			var offset = $(':target').offset();
			//var scrollto = offset.top - 248;

			//$('html, body').animate( { scrollTop:scrollto }, 0 );

		},

		// Slider function
		sliderFunction: function() {
			$('.slider').slick({
			  infinite: true,
			  speed: 400,
			  autoplaySpeed: 2500,
			  cssEase: 'linear',
			  pauseOnHover: false,
			  autoplay: true
			});

		},


		// Modal
		modal: function() {

			$('[href$=".jpg"], [href$=".JPG"], [href$=".png"], [href$=".gif"]').colorbox({
				transition: 'elastic',
				opacity: 0.8,
				slideshow: false,
				slideshowSpeed: 4000,
				itemsnitialWidth: 50,
				initialHeight: 50,
				maxWidth: '90%',
				maxHeight: '90%',
			});

		},


		// InViewElement function
		inViewElement: function() {

			$('.content-page__section').waypoint(function(direction) {

				if (direction === 'down') {
					var dom_element = $(this)[0].element;
					var nome = $(dom_element).attr('id');
					$('.header-page__call-to-action').removeClass().addClass('header-page__call-to-action header-page__call-to-action--' + nome);
				}

			}, {
				offset: '248'
			});


			$('.content-page__section').waypoint(function(direction) {

				if (direction === 'up') {
					var dom_element = $(this)[0].element;
					var nome = $(dom_element).attr('id');
					$('.header-page__call-to-action').removeClass().addClass('header-page__call-to-action header-page__call-to-action--' + nome);
				}

			}, {
				offset: function() {
				    return -this.element.clientHeight;
				}
			});

		}
	};

	siteInit.DOMready();

});